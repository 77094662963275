<template>
  <b-overlay
    :show="loading"

    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Домен"
              label-for="domain"
            >
              <v-select
                id="select-domain"
                v-model.trim="item.domain"
                placeholder="Выберите домен"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="domains"
                :reduce="val => val.value"
                :clearable="false"
                input-id="domain"
                label="name"
                :class="{
                  'is-invalid': !!get(validation, 'domain[0]')
                }"
              >
                <template #no-options>
                  Нет доступных доменов
                </template>
              </v-select>

              <small
                v-if="!!get(validation, 'domain[0]')"
                class="text-danger"
              >
                {{ get(validation, 'domain[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Режим работы"
              label-for="mode"
            >
              <v-select
                id="select-mode"
                v-model.trim="item.mode"
                placeholder="Режим работы"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="siteModes"
                :reduce="val => val.value"
                :clearable="false"
                input-id="mode"
                label="name"
                :class="{
                  'is-invalid': !!get(validation, 'mode[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'mode[0]')"
                class="text-danger"
              >
                {{ get(validation, 'mode[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Ключ"
              label-for="key"
            >
              <!--              v-mask="{ regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$' }"-->
              <b-form-input
                id="key"
                v-model.trim="item.key"

                v-mask="{ regex: '^[a-z0-9]+(?:-[a-z0-9]+)*$', placeholder: '' }"
                placeholder="Введите ключ"
                :state="get(validation, 'key[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'key[0]')"
                class="text-danger"
              >
                {{ get(validation, 'key[0]') }}
              </small>
              <small
                class="text-secondary"
              >
                Если не заполнить будет сгенерирован автоматически. Допустимы срочные латинские символы и дефис.
              </small>

            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите название"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Выберите акцентный цвет"
            >
              <a-color-picker
                id="colors-primary"
                v-model="item.dev_data.colors.primary"
              />
              <small
                v-if="!!get(validation, 'colors.primary[0]')"
                class="text-danger"
              >
                {{ get(validation, 'colors.primary[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Выберете дополнительный цвет"
            >
              <a-color-picker
                id="colors-secondary"
                v-model="item.dev_data.colors.secondary"
                palette="secondary"
              />
              <small
                v-if="!!get(validation, 'colors.secondary[0]')"
                class="text-danger"
              >
                {{ get(validation, 'colors.secondary[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <h4 class="mt-1">
              Контакты
            </h4>

            <b-form-group
              label="Телефон"
              label-for="phone"
            >
              <a-phone-input
                id="phone"
                v-model.trim="item.dev_data.phone"
              />
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <h4 class="mt-1">
              Социальные сети
            </h4>

            <b-form-group
              v-for="(socItem, indexSocItem) in item.dev_data.social"
              :key="'si' + indexSocItem"
            >
              <label :for="'si' + indexSocItem">
                <template v-if="socItem.key === 'vk'">
                  ВКонтакте
                </template>
                <template v-else-if="socItem.key === 'telegram'">
                  Telegram канал
                </template>
                <template v-else-if="socItem.key === 'youtube'">
                  Youtube
                </template>
                <template v-else-if="socItem.key === 'ok'">
                  Одноклассники
                </template>
                <template v-else-if="socItem.key === 'dzen'">
                  Дзен
                </template>
              </label>
              <b-input-group class="input-group-merge">
                <b-form-input
                  :id="'si' + indexSocItem"
                  v-model.trim="socItem.value"
                  placeholder="Введите ссылку"
                />
                <b-input-group-append is-text>
                  <vk-icon
                    v-if="socItem.key === 'vk'"
                    class="my-n1"
                  />
                  <telegram-icon
                    v-else-if="socItem.key === 'telegram'"
                    class="my-n1"
                  />
                  <youtube-icon
                    v-else-if="socItem.key === 'youtube'"
                    class="my-n1"
                  />
                  <ok-icon
                    v-else-if="socItem.key === 'ok'"
                    class="my-n1"
                  />
                  <dzen-icon
                    v-else-if="socItem.key === 'dzen'"
                    class="my-n1"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="false"
            cols="12"
            md="12"
          >
            <h4 class="mt-1">
              Мессенджеры
            </h4>

            <b-form-group
              v-for="(messengerItem, indexMessengerItem) in item.dev_data.messengers"
              :key="'mi' + indexMessengerItem"
            >
              <label :for="'mi' + messengerItem">
                <template v-if="messengerItem.key === 'vk'">
                  ВКонтакте
                </template>
                <template v-else-if="messengerItem.key === 'telegram'">
                  Telegram
                </template>
                <template v-else-if="messengerItem.key === 'whatsapp'">
                  WhatsApp
                </template>
                <template v-else-if="messengerItem.key === 'viber'">
                  Viber
                </template>
              </label>
              <b-input-group class="input-group-merge">
                <b-form-input
                  :id="'mi' + indexMessengerItem"
                  v-model.trim="messengerItem.value"
                  placeholder="Введите ссылку"
                />
                <b-input-group-append is-text>
                  <vk-icon
                    v-if="messengerItem.key === 'vk'"
                    class="my-n1"
                  />
                  <telegram-icon
                    v-else-if="messengerItem.key === 'telegram'"
                    class="my-n1"
                  />
                  <whatsapp-icon
                    v-else-if="messengerItem.key === 'whatsapp'"
                    class="my-n1"
                  />
                  <viber-icon
                    v-else-if="messengerItem.key === 'viber'"
                    class="my-n1"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="false">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Шрифт"
                label-for="font"
              >
                <v-select
                  id="select-font"
                  v-model.trim="item.dev_data.font"
                  placeholder="Шрифт"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="fonts"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="font"
                  label="name"
                  :class="{
                    'is-invalid': !!get(validation, 'font[0]')
                  }"
                />
                <small
                  v-if="!!get(validation, 'font[0]')"
                  class="text-danger"
                >
                  {{ get(validation, 'font[0]') }}
                </small>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <h4 class="mt-1">
                Социальные сети
              </h4>

              <b-form-group
                v-for="(socItem, indexSocItem) in item.dev_data.social"
                :key="'si' + indexSocItem"
              >
                <label :for="'si' + indexSocItem">
                  <template v-if="socItem.key === 'vk'">
                    ВКонтакте
                  </template>
                  <template v-else-if="socItem.key === 'telegram'">
                    Telegram канал
                  </template>
                  <template v-else-if="socItem.key === 'youtube'">
                    Youtube
                  </template>
                  <template v-else-if="socItem.key === 'ok'">
                    Одноклассники
                  </template>
                  <template v-else-if="socItem.key === 'dzen'">
                    Дзен
                  </template>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :id="'si' + indexSocItem"
                    v-model.trim="socItem.value"
                    placeholder="Введите ссылку"
                  />
                  <b-input-group-append is-text>
                    <vk-icon
                      v-if="socItem.key === 'vk'"
                      class="my-n1"
                    />
                    <telegram-icon
                      v-else-if="socItem.key === 'telegram'"
                      class="my-n1"
                    />
                    <youtube-icon
                      v-else-if="socItem.key === 'youtube'"
                      class="my-n1"
                    />
                    <ok-icon
                      v-else-if="socItem.key === 'ok'"
                      class="my-n1"
                    />
                    <dzen-icon
                      v-else-if="socItem.key === 'dzen'"
                      class="my-n1"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <h4 class="mt-1">
                Мессенджеры
              </h4>

              <b-form-group
                v-for="(messengerItem, indexMessengerItem) in item.dev_data.messengers"
                :key="'mi' + indexMessengerItem"
              >
                <label :for="'mi' + messengerItem">
                  <template v-if="messengerItem.key === 'vk'">
                    ВКонтакте
                  </template>
                  <template v-else-if="messengerItem.key === 'telegram'">
                    Telegram
                  </template>
                  <template v-else-if="messengerItem.key === 'whatsapp'">
                    WhatsApp
                  </template>
                  <template v-else-if="messengerItem.key === 'viber'">
                    Viber
                  </template>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :id="'mi' + indexMessengerItem"
                    v-model.trim="messengerItem.value"
                    placeholder="Введите ссылку"
                  />
                  <b-input-group-append is-text>
                    <vk-icon
                      v-if="messengerItem.key === 'vk'"
                      class="my-n1"
                    />
                    <telegram-icon
                      v-else-if="messengerItem.key === 'telegram'"
                      class="my-n1"
                    />
                    <whatsapp-icon
                      v-else-if="messengerItem.key === 'whatsapp'"
                      class="my-n1"
                    />
                    <viber-icon
                      v-else-if="messengerItem.key === 'viber'"
                      class="my-n1"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </template>

        </b-row>
        <b-row v-if="false">

          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            md="6"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--              label="Favicon"-->
          <!--              label-for="favicon"-->
          <!--            >-->
          <!--              <b-form-file-->
          <!--                id="favicon"-->
          <!--                v-model="item.favicon"-->
          <!--                placeholder="Выберите файл или перетащите его сюда..."-->
          <!--                :state="get(validation, 'favicon[0]') ? false : null"-->
          <!--                drop-placeholder="Перетащите файл сюда..."-->
          <!--              />-->
          <!--              <small-->
          <!--                v-if="!!get(validation, 'favicon[0]')"-->
          <!--                class="text-danger"-->
          <!--              >-->
          <!--                {{ get(validation, 'favicon[0]') }}-->
          <!--              </small>-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            md="6"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--              label="Логотип"-->
          <!--              label-for="logo"-->
          <!--            >-->
          <!--              <b-form-file-->
          <!--                id="logo"-->
          <!--                v-model="item.logo"-->
          <!--                placeholder="Выберите файл или перетащите его сюда..."-->
          <!--                :state="get(validation, 'logo[0]') ? false : null"-->
          <!--                drop-placeholder="Перетащите файл сюда..."-->
          <!--              />-->
          <!--              <small-->
          <!--                v-if="!!get(validation, 'logo[0]')"-->
          <!--                class="text-danger"-->
          <!--              >-->
          <!--                {{ get(validation, 'logo[0]') }}-->
          <!--              </small>-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->

        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createItem"
            >
              Создать
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, BOverlay,
  BInputGroup, BInputGroupAppend, BFormFile,
} from 'bootstrap-vue'
import APhoneInput from '@/components/form/APhoneInput.vue'
import vSelect from 'vue-select'

import get from 'lodash/get'
import { getCurrentInstance, ref, nextTick } from 'vue'
import useCrudCreate from '@/composables/useCrudCreate'
import { createWebsite } from '@/services/main-api/websites/stores/websites'
import { getDomains } from '@/services/main-api/domains'
import siteModes from '@/modules/sites-section/sites/composables/siteModes'
import siteTypes from '@/modules/sites-section/sites/composables/siteTypes'
import fonts from '@/modules/sites-section/site-store-section/site-store/composables/fontsList'

import VkIcon from '@/components/icons/VkIcon.vue'
import TelegramIcon from '@/components/icons/TelegramIcon.vue'
import YoutubeIcon from '@/components/icons/YoutubeIcon.vue'
import OkIcon from '@/components/icons/OkIcon.vue'
import DzenIcon from '@/components/icons/DzenIcon.vue'
import ViberIcon from '@/components/icons/ViberIcon.vue'
import WhatsappIcon from '@/components/icons/WhatsappIcon.vue'
import AColorPicker from '../components/AColorPicker.vue'

export default {
  name: 'CreateWarehouseView',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BOverlay,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    AColorPicker,
    VkIcon,
    TelegramIcon,
    YoutubeIcon,
    OkIcon,
    DzenIcon,
    ViberIcon,
    WhatsappIcon,
    APhoneInput,
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const siteType = 'store'

    const domains = ref([])

    const getActiveDomains = async () => {
      try {
        const res = await getDomains({
          companyID: instance.$store.getters['workingMode/selected_company_id'],
          perPage: 1000,
          search: 'status:verified',
        })
        domains.value = [
          {
            id: null,
            name: 'Нет',
            value: null,
          },
          ...res.data.data.map(d => ({
            id: d.id,
            name: d.name,
            value: d.name,
          })),
        ]
      } catch (e) {
        domains.value = []
      }
    }

    const createEntity = async () => {
      await createWebsite({
        project_id: instance.$store.getters['workingMode/selected_project_id'],
        domain: instance.item.domain,
        name: instance.item.name,
        description: instance.item.description,
        key: instance.item.key,
        mode: instance.item.mode,
        dev_data: instance.item.dev_data,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      checkValidation,
      clearForm,
      catchValidation,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        name: '',
        domain: '',
        description: '',
        key: '',
        mode: siteModes[0].value,
        dev_data: {
          messengers: [
            { key: 'telegram', value: '' },
            { key: 'whatsapp', value: '' },
            { key: 'viber', value: '' },
            { key: 'vk', value: '' },
          ],
          social: [
            { key: 'vk', value: '' },
            { key: 'telegram', value: '' },
            { key: 'youtube', value: '' },
            { key: 'ok', value: '' },
            { key: 'dzen', value: '' },
          ],
          font: 'Roboto',
          colors: {
            primary: 'blue',
            secondary: 'blue',
          },
          phone: '',
        },
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      getMoreData: async () => {
        await getActiveDomains()
      },
      createEntity,
      getTextAfterCreation: () => 'Сайт был создан.',
      runAfterCreation: async () => {
        await instance.$router.push({ name: 'sites' })
      },
      catchErrorValidationFunctions: async () => {
        await nextTick()
        const error = document.querySelector('small.text-danger')
        error.scrollIntoView({ behavior: 'smooth', block: 'center' })
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,
      siteType,
      domains,

      siteTypes,
      siteModes,

      fonts,

      checkValidation,
      clearForm,
      catchValidation,
      createItem,
      get,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
