const types = [
  {
    name: 'Внешний',
    categoryTitle: 'Внешние',
    value: 'external',
    icon: 'ServerIcon',
  },
  {
    name: 'Интернет-магазин',
    categoryTitle: 'Интернет-магазины',
    value: 'store',
    icon: 'ShoppingBagIcon',
  },
]

export default types

const futureTypes = [

  {
    name: 'Лендинг',
    categoryTitle: 'Лендинги',
    value: 'landing',
    icon: 'TrelloIcon',
  },
  {
    name: 'Блог',
    categoryTitle: 'Блоги',
    value: 'blog',
    icon: 'SidebarIcon',
  },
]

export { futureTypes }
